import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OrderProduced from '../views/OrderProduced.vue'
import OrderSent from '../views/OrderSent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/orderProduced',
    name: 'OrderProduced',
    component: OrderProduced    
  },
  {
    path: '/orderSent',
    name: 'OrderSent',
    component: OrderSent    
  }
]

const router = new VueRouter({
  routes
})

export default router
