<template>
  <v-app>
    <v-navigation-drawer
      app
      clipped
      :permanent="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
      v-model="navigation"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Printolino Workdesk
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="{ name: item.to }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
        <v-list-item-content>
          <v-switch
            v-model="webcam"
            label="Webcam-Scanner"
          ></v-switch>
          <div v-if="webcam">
            <StreamBarcodeReader
              @decode="onScanner"
            ></StreamBarcodeReader>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-main>
      <v-app-bar v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
        <v-app-bar-nav-icon @click="navigation = !navigation" dense></v-app-bar-nav-icon>
      </v-app-bar>
      <v-container>
        <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
          <div>
            <StreamBarcodeReader
              @decode="onScanner"
            ></StreamBarcodeReader>
          </div>
          <br>
        </div>
        <router-view ref="view" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
// import _ from 'lodash';

export default {
  name: 'App',

  components: {
    StreamBarcodeReader
  },
  data: () => ({
    latestScanCode: '',
    latestScanCodeTimer: null,
    items: [
      {
        icon: 'mdi-package-variant',
        title: 'Verpacken',
        to: 'OrderProduced'
      },
      {
        icon: 'mdi-truck',
        title: 'Versenden',
        to: 'OrderSent'
      },
    ],
    webcam: false,
    beep: new Audio('/beep.mp3'),
    navigation: true,
  }),
  created() {
    this.$barcodeScanner.init(this.onDecode)
  },
  methods: {
    onDecode(data) {
      if (data !== this.latestScanCode) {
        this.latestScanCode = data;
        
        this.understandCode(data, (err,message) => {
          this.$refs.view.scancode(message);
        });
      }
      if (this.latestScanCodeTimer) {
        clearTimeout(this.latestScanCodeTimer);
      }
      this.latestScanCodeTimer = setTimeout(() => {
        this.latestScanCode = ''
      }, 1000);
    },
    onScanner(data) {
      this.beep.play();

      this.onDecode(data);
    },
    understandCode(data, next) {
      const urlDataParts = data.split('/');
      if (data.startsWith('9') && data.length === 18) {
        next(null, {
          type: 'postTracking',
          id: data
        })
      }
      else if (data.startsWith('http') && urlDataParts.length === 6 && urlDataParts[3] === 'shortcut') {
        next(null, {
          type: 'document',
          documentType: urlDataParts[4],
          id: urlDataParts[5]
        })
      }

    }
  }
};
</script>
