<template>
    <div>
        <div class="text-h5">Verpacken</div>
        <br><br>
        
        <v-alert v-for="(e,idx) in log" :key="idx" :type="e.code === 'ok' ? 'success' : 'error'" prominent>
            <v-row align="center">
                <v-col class="grow">
                    {{ e.message }}
                </v-col>
                <v-col class="shrink" v-if="e.data && e.data.actions">
                    <nobr>
                      <v-btn v-for="(action,idx) in e.data.actions" :key="idx" color="primary" @click="runAction(action)" class="mb-3">
                          <template v-if="action.type === 'printShipment'">
                            <v-icon left dark>mdi-printer</v-icon> Lieferschein 
                          </template>
                          <template v-else-if="action.type === 'printInvoice'">
                            <v-icon left dark>mdi-printer</v-icon> Rechnung
                          </template>
                          <template v-else>
                              {{ action.type }}
                          </template>
                      </v-btn>
                    </nobr>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>

export default {
  name: 'OrderProduced',
  data() {
    return {
      log: []
    }
  },
  methods: {
    scancode(data) {
      if (data.type !== 'document') {
        return this.alert({ type:'error', message:'Dieser Code kann auf dieser Seite nicht verwendet werden' })
      }

      const postData = {};
      postData[data.documentType+'Id'] = data.id;
      this.runAction({ type:'orderProduced', data:postData });
    },
    alert(data) {
      this.log.unshift(data);
    },
    runAction(action) {
      this.$axios.post('/api/work/'+action.type, action.data).then((response) => {
        this.alert(response.data);
      });
    }
  }
}
</script>
