<template>
    <div>
        <div class="text-h5">Versenden</div>
        <br><br>
        <!-- <v-alert
            border="top"
            colored-border
            type="info"
            >
            Vestibulum ullamcorper mauris at ligula. Nam pretium turpis et arcu. Ut varius tincidunt libero. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Morbi nec metus.
        </v-alert> -->
        <v-spacer></v-spacer>
        <PostTrackingCodeScanning :open="orderLoaded" ref="posttrackingscanner" @scannedPostTrackingCodes="scannedPostTrackingCodes" @close="orderLoaded = null">
            <template v-if="orderLoaded">
                Auftrag #{{ orderLoaded.no }}
            </template>
        </PostTrackingCodeScanning>
        <v-alert v-for="(e,idx) in log" :key="idx" :type="e.code === 'ok' ? 'success' : 'error'" prominent>
            <v-row align="center">
                <v-col class="grow">
                    {{ e.message }}
                </v-col>
                <v-col class="shrink" v-if="e.data && e.data.actions">
                    <nobr>
                      <v-btn v-for="(action,idx) in e.data.actions" :key="idx" color="primary" @click="runAction(action)" class="mb-3">
                          <template v-if="action.type === 'printShipment'">
                            <v-icon left dark>mdi-printer</v-icon> Lieferschein 
                          </template>
                          <template v-else-if="action.type === 'printInvoice'">
                            <v-icon left dark>mdi-printer</v-icon> Rechnung
                          </template>
                          <template v-else>
                              {{ action.type }}
                          </template>
                      </v-btn>
                    </nobr>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import PostTrackingCodeScanning from '@/components/PostTrackingCodeScanning.vue';

export default {
  name: 'OrderSent',
  components: {
      PostTrackingCodeScanning
  },
  data() {
    return {
      log: [],
      orderLoaded: null, 
      postTracingCodeScanningActive: false

//      documentType: null,
//      documentId: null,
    }
  },
  methods: {
    scancode(data) {
        if (this.orderLoaded) {
            return this.$refs.posttrackingscanner.scancode(data);
        }

        if (data.type !== 'document') {
            return this.alert({ type:'error', message:'Scanne zuerst ein Dokument ein' });
        }

//        this.documentType = data.documentType;
//        this.documentId = data.documentId;

        const postData = {};
        postData[data.documentType+'Id'] = data.id;
        this.runAction({ type:'checkOrder', data:postData }, this.onOrderLoaded);
    },
    alert(data) {
      this.log.unshift(data);
    },
    onOrderLoaded(response) {
        if (response.code === 'error') {
            return this.alert(response);
        }

        if (response.data.status !== 'produced') {
            return this.alert({ type:'error', message:'Der Auftrag #'+response.data.no+' hat nicht den Status "produziert"' });
        }

        if (response.data.isPickupOrder === true) {
            return  this.runAction({ type:'orderSent', data:{ orderId:response.data.id } });
        }

        this.orderLoaded = response.data;
    },
    runAction(action, next) {
      this.$axios.post('/api/work/'+action.type, action.data).then((response) => {
        if (typeof next === 'function') {
            return next(response.data);
        }
        this.alert(response.data);
      });
    },
    scannedPostTrackingCodes(trackingCodes) {
        this.runAction({ type:'orderSent', data:{ orderId:this.orderLoaded.id, trackingCodes } });

        this.orderLoaded = null;
    },
  },
}
</script>
