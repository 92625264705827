<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
    }
  },
  methods: {
    scancode() {
    }
  }
}
</script>
