<template>
    <v-dialog
      v-model="open"
      width="500"
    >
        <v-card>
            <v-card-title class="headline grey lighten-2">
                Bitte Posttracking-Kleber scannen
            </v-card-title>

            <v-spacer></v-spacer>
            <br>
            
            <v-card-text>
               <slot></slot>
            </v-card-text>

            <v-spacer></v-spacer>
            
            <v-card-text>
                <v-list-item-group>
                    <v-list-item v-for="code in trackingCodes" :key="code">
                        <v-list-item-content>
                            <v-list-item-title>{{ code }}</v-list-item-title>
                        </v-list-item-content> 

                        <v-list-item-action>
                            <v-btn @click="removeCodeFromList(code)" icon><v-icon>mdi-delete</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="close()"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="save()"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

  export default {
    name: 'PostTrackingCodeScanning',
    props: {
        open: Boolean
    },
    data: () => ({
      trackingCodes: []
    }),
    methods: {
        scancode(data) {
            if (data.type !== 'postTracking') {
                return this.alert({ type:'error', message:'Scanne jetzt den Trackingcode zum Bestellung ein' })
            }
            if (!this.trackingCodes.includes(data.id)) {
                this.trackingCodes.push(data.id);
            }
        },
        removeCodeFromList(code) {
            this.trackingCodes = _.without(this.trackingCodes, code);
        },
        alert() {

        },
        close() {
            this.$emit('close');
        },
        save() {
            this.$emit('scannedPostTrackingCodes', this.trackingCodes);
            this.trackingCodes = [];
        }
    }
  }
</script>
